export function getCurrentDate(): Date {
    return new Date();
}

export function addHours(date: Date, hours: number): Date {
    return new Date(new Date(date).setHours(date.getHours() + hours));
}

export function addMinutes(date: Date, minutes: number): Date {
    return new Date(new Date(date).setMinutes(date.getMinutes() + minutes));
}

export function addDays(date: Date, daysToAdd: number): Date {
    const result = new Date(date);
    result.setDate(date.getDate() + daysToAdd);
    return result;
}

export function getFormattedDate(date: Date | undefined | null): string {
    if (!date) {
        return "";
    }
    const dateMonth = `0${date.getMonth() + 1}`;
    const dateDay = `0${date.getDate()}`;
    const formattedDate = `${date.getFullYear()}-${dateMonth.slice(
        -2
    )}-${dateDay.slice(-2)}`;

    return formattedDate;
}

export function getFormattedDateTime(date: Date | undefined | null): string {
    return !date
        ? ""
        : `${getFormattedDate(
              date
          )} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}
